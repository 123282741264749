<template>
    
    <div class="h-auto rounded-lg flex flex-col overflow-hidden bg-filters relative">
        <div class="h-auto py-2 flex flex-row justify-between items-center px-2">
            <span class="text-font-gray font-semibold text-xs">Margin</span>
        </div>
        <div class="h-32 flex flex-row justify-center items-center mb-5">
            <MarginAverageMobile/>
        </div>
    </div>

</template>
<script>
    import MarginAverageMobile from "@/charts/MarginAverageMobile";
    export default {
        components: {
            MarginAverageMobile
        }
    }
</script>